import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants';
import { cleanObject, getFormattedDate } from 'src/functions/common-functions';

@Injectable({
  providedIn: 'root',
})
export class ReembolsoService {
  _baseURL: string;
  path: string;
  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.apiBienestar;
    this.path = this._baseURL + '/v1/monitor/reembolso';
  }

  getToken() {
    return localStorage.getItem('userToken');
  }
  getTramiteById(idTramite): Observable<any> {
    const url = `${this.path}/tramites/${idTramite}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  getDocuments(idTramite) {
    const url = `${this.path}/tramites/${idTramite}/documentos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getProvee(): Observable<any> {
    const url = `${this.path}/proveedores`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  reasignProveedor(idTramite, body): Observable<any> {
    const url = `${this.path}/tramites/${idTramite}/proveedor`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  reasignarTramite(body): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/gmm/reasignar`;
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  indicadoresGeneral(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/gmm/indicadores-general`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  indicadores(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/gmm/indicadores`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  userAsignacion(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/usuarios/gmm/analistas`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listStatus(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/gmm/status-tramite`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listStatusTramite(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/gmm/status-tramite`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listSiniestroSupervisor(p): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/gmm/tramites`;

    let params = cleanObject(p);

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getTramites(p): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/gmm/mis-tramites`;

    let params = cleanObject(p);

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getInfPolizaGenerales(id, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/info-poliza`;

    // if (modulo === 'cirugia') {
    //   url = `${this._baseURL}/v1/monitor/cirugia/tramites/${id}/info-poliza`;
    // }
    if (modulo === 'indemnizacion') {
      url = `${this._baseURL}/v1/monitor/indemnizacion/tramites/${id}/info-poliza`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getInfoPolizaCobertura(id, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/coberturas`;

    // if (modulo === 'cirugia') {
    //   url = `${this._baseURL}/v1/monitor/cirugia/tramites/${id}/coberturas`;
    // }

    if (modulo === 'indemnizacion') {
      url = `${this._baseURL}/v1/monitor/indemnizacion/tramites/${id}/coberturas`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getInfoPolizaAgente(id, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/agente-ejecutivo`;

    // if (modulo === 'cirugia') {
    //   url = `${this._baseURL}/v1/monitor/cirugia/tramites/${id}/agente-ejecutivo`;
    // }

    if (modulo === 'indemnizacion') {
      url = `${this._baseURL}/v1/monitor/indemnizacion/tramites/${id}/agente-ejecutivo`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getInfoPolizaCobranza(id, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/cobranza`;

    // if (modulo === 'cirugia') {
    //   url = `${this._baseURL}/v1/monitor/cirugia/tramites/${id}/cobranza`;
    // }

    if (modulo === 'indemnizacion') {
      url = `${this._baseURL}/v1/monitor/indemnizacion/tramites/${id}/cobranza`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getInfoPolizaSiniestros(id, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/siniestro`;

    // if (modulo === 'cirugia') {
    //   url = `${this._baseURL}/v1/monitor/cirugia/tramites/${id}/siniestro`;
    // }

    if (modulo === 'indemnizacion') {
      url = `${this._baseURL}/v1/monitor/indemnizacion/tramites/${id}/siniestro`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getInfoPolizaSiniestroDetalle(id, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/details/${id}`;

    if (modulo === 'cirugia') {
      url = `${this._baseURL}/v1/monitor/cirugia/tramites/details/${id}`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getAnotaciones(id, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/anotaciones/${id}`;

    if (modulo === 'cirugia') {
      url = `${this._baseURL}/v1/monitor/cirugia/tramites/anotaciones/${id}`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getMovimientos(id, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/movimientos/${id}`;

    if (modulo === 'cirugia') {
      url = `${this._baseURL}/v1/monitor/cirugia/tramites/movimientos/${id}`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getReservas(id, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/reservas/${id}`;

    if (modulo === 'cirugia') {
      url = `${this._baseURL}/v1/monitor/cirugia/tramites/reservas/${id}`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getInfoPolizaClausulas(id, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/clausulas`;

    // if (modulo === 'cirugia') {
    //   url = `${this._baseURL}/v1/monitor/cirugia/tramites/${id}/clausulas`;
    // }

    if (modulo === 'indemnizacion') {
      url = `${this._baseURL}/v1/monitor/indemnizacion/tramites/${id}/clausulas`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getInfoPolizaDetalleClausulas(id, params, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/clausulas/detalle`;

    if (modulo === 'cirugia') {
      url = `${this._baseURL}/v1/monitor/cirugia/tramites/${id}/clausulas/detalle`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getDetalleClausulaEspecial(id, params, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/clausulas/detalle/especial`;

    if (modulo === 'cirugia') {
      url = `${this._baseURL}/v1/monitor/cirugia/tramites/${id}/clausulas/detalle/especial`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getDetalleClausulaEstandar(id, params, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/clausulas/detalle/estandar`;

    if (modulo === 'cirugia') {
      url = `${this._baseURL}/v1/monitor/cirugia/tramites/${id}/clausulas/detalle/estandar`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getInfoPolizaDetalleTabulador(params): Observable<any> {
    const url = `${this._baseURL}/catalogos/tabulador`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  facturasTotales(id): Observable<any> {
    const url = `${this.path}/tramites/${id}/facturas/totales`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getFacturas(id, page): Observable<any> {
    const url = `${this.path}/tramites/${id}/facturas`;

    let params = { page };

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getConceptosValoracion(id): Observable<any> {
    const url = `${this._baseURL}/catalogos/conceptos-valoracion-ramo`;

    let params = { id };

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getMotivosRechazo(id, modulo?, ingresoRenglon?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/gmm/motivos-rechazo`;

    // cable de Felix
    if (modulo == 'ingreso' && ingresoRenglon) modulo = 'ingreso_edc';

    if (modulo) url = `${url}?tipoTramite=${modulo}`;

    let params = { id };

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearFactura(id, factura): Observable<any> {
    const url = `${this._baseURL}/v1/facturas/crear`;

    var formData = new FormData();

    let fecha = getFormattedDate(factura.fecha, 'yyyy-mm-dd');

    formData.append('reclamacionId ', id);
    formData.append('fecha ', fecha);
    formData.append('folio ', factura.folio);
    formData.append('rfcEmisor ', factura.rfcEmisor);
    formData.append('rfcReceptor ', factura.rfcReceptor);
    formData.append('nmEmisor ', factura.nmEmisor);
    formData.append('nmReceptor ', factura.nmReceptor);
    formData.append('subtotal', factura.subtotal);
    formData.append('total', factura.total);
    formData.append('detalles', JSON.stringify(factura.detalles));
    formData.append('pdf', factura.pdf);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getMotivosDevolucion(modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/gmm/motivos-devolucion`;
    if (modulo) url = `${url}?tipoTramite=${modulo}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getMotivosDesvío(modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/gmm/motivos-desvio`;
    if (modulo) url = `${url}?tipoTramite=${modulo}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getTiposDescuento(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/gmm/tipos-descuento`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  devolucionAdministrativa(body): Observable<any> {
    const url = `${this._baseURL}/v1/facturas/motivo-devolucion`;

    console.log(body);

    // var formData = new FormData();
    // formData.append('facturaId ', body.FacturaId);
    // formData.append('codDevolucion ', body.codDevolucion);
    // formData.append('observacion ', body.observacion);
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getTramiteDocumentos(id, modulo): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/${modulo}/tramites/${id}/documentos `;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getConsultaMedica(params): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/busqueda/cedula-medica`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  documentValitationInforme(json, id: number, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/devolver-documentos`;

    if (modulo == 'ingreso')
      url = `${this._baseURL}/v1/monitor/ingreso/tramites/${id}/devolver-documentos`;

    if (modulo == 'indemnizacion')
      url = `${this._baseURL}/v1/monitor/indemnizacion/tramites/${id}/devolver-documentos`;

    return this.http
      .post(url, json, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  siniestrosSubsecuentes(params): Observable<any> {
    const url = `${this._baseURL}/v1/siniestros/subsecuentes/reembolso`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getSubsecuentesPaginado(params): Observable<any> {
    const url = `${this._baseURL}/v1/siniestros/subsecuentes/monitor/gmm`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  guardarCambiosFactura(id, idFactura, renglones): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/facturas/${idFactura}/guardar-cambios`;

    return this.http
      .post(
        url,
        { renglones },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  enviarCorreo(id): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/facturas/enviar-correo`;

    return this.http

      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',

          Authorization: this.getToken(),
        }),
      })

      .pipe(map((data) => data));
  }

  getCausasByTramite(params): Observable<any> {
    const url = `${this._baseURL}/v1/gestor-tramites/gmm/reembolso/causas`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }
  getAtencionByTramite(params): Observable<any> {
    const url = `${this._baseURL}/v1/gestor-tramites/gmm/reembolso/tipo-atenciones`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  aprobarRechazarTramite(id, body): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/aprobar-rechazar`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listTipoAnotaciones(): Observable<any> {
    const url = `${this._baseURL}/catalogos/anotaciones`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  ListAnotacionesSiniestro(id, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/${id}/anotaciones`;

    if (modulo === 'indemnizacion') {
      url = `${this._baseURL}/v1/monitor/indemnizacion/${id}/anotaciones`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  createAnotacion(id, body, modulo?): Observable<any> {
    let url = `${this._baseURL}/v1/monitor/reembolso/${id}/anotaciones`;

    if (modulo === 'indemnizacion') {
      url = `${this._baseURL}/v1/monitor/indemnizacion/${id}/anotaciones`;
    }

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  deleteAnotacion(id): Observable<any> {
    if (!id) id = -1;

    const url = `${this._baseURL}/v1/monitor/anotaciones/${id}`;

    return this.http
      .delete(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  editAnotacion(id, body): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/anotaciones/${id}`;

    return this.http
      .put(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  previewFiniquito(id, body): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/facturas/carta-dictamen`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  generarFiniquito(id, body): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/facturas/generar-finiquito`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  descargarFiniquito(id, body): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/reembolso/tramites/${id}/facturas/descargar-finiquito`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getRoles(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/usuarios/roles/gastos-medicos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getUsuarios(roles): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/usuarios/gmm`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params: roles,
      })
      .pipe(map((data) => data));
  }

  getStatusTramite(): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/gmm/status-tramite`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getReporte(params): Observable<any> {
    const url = `${this._baseURL}/v1/monitor/gastos-medicos/reporte?`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getAgentes(search): Observable<any> {
    const url = `${this._baseURL}/catalogos/agentes?search=${search}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  catalogosSE(): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar/catalogos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  subirCatalogoSE(catalogo, archivo): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar/subir-catalogo`;

    var formData = new FormData();
    formData.append('archivo ', archivo);
    formData.append('catalogo ', catalogo);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getGrupos(search): Observable<any> {
    const url = `${this._baseURL}/catalogos/grupos?search=${search}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getSubGrupos(codes, search?): Observable<any> {
    if (!search) search = '';

    const url = `${this._baseURL}/catalogos/subgrupos?codes=${codes}&search=${search}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getOficinas(): Observable<any> {
    const url = `${this._baseURL}/catalogos/oficinas`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getRamos(): Observable<any> {
    const url = `${this._baseURL}/catalogos/ramos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getTiposTramite(): Observable<any> {
    const url = `${this._baseURL}/catalogos/gmm/tipos-tramite`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getDX(): Observable<any> {
    const url = `${this._baseURL}/v1/gestor-tramites/diagnosticos/tipo-diagnosticos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getProvincias(): Observable<any> {
    const url = `${this._baseURL}/catalogos/provincias`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
}
