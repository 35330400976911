import { Domicilio } from './../../reembolso/crear-tramite/models/crear-tramite.interface';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiCabinaService } from '../../cabina/services/api-cabina.service';

@Component({
  selector: 'app-formulario-direccion',
  templateUrl: './formulario-direccion.component.html',
  styleUrls: ['./formulario-direccion.component.scss'],
})
export class FormularioDireccionComponent implements OnInit {
  formDomicilio: FormGroup;
  localidad: any;
  arrayColonias: [];
  @Input() arraySiglas: Array<any>;
  @Input() asegurado;
  @Input() typePerson;
  @Input() resetRows?;
  @Input() domicilio: Domicilio;
  showTable: boolean = true;
  showForm: boolean = false;
  rows: any = [];
  isSearching: any;
  selected: any;
  noDataError = false;
  siguiente = false;
  tableHeaders = [
    {
      name: 'tipodomicilio',
      label: 'Tipo',
    },
    {
      name: 'domicilio',
      label: 'Domicilio',
    },
    {
      name: 'nminterior',
      label: 'No. Interior',
    },
    {
      name: 'nmexterior',
      label: 'No. Exterior',
    },
    {
      name: 'cdpostal',
      label: 'Código Postal',
    },
    {
      name: 'estado',
      label: 'Estado',
    },
    {
      name: 'municipio',
      label: 'Municipio',
    },
    {
      name: 'ciudad',
      label: 'Ciudad',
    },
    {
      name: 'colonia',
      label: 'Colonia',
    },
  ];
  constructor(
    private fb: FormBuilder,
    private apiCabinaService: ApiCabinaService
  ) {
    this.formDomicilio = this.fb.group({
      siglas: [null, Validators.required],
      domicilio: [null, Validators.required],
      nmexterior: [null, Validators.required],
      nminterior: [null],
      cdpostal: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(5)]),
      ],
      pais: [{ value: null, disabled: true }, Validators.required],
      estado: [{ value: null, disabled: true }, Validators.required],
      municipio: [{ value: null, disabled: true }, Validators.required],
      ciudad: [{ value: null, disabled: true }, Validators.required],
      colonia: [null, Validators.required],
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ]),
      ],
    });
  }

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resetRows) {
      this.rows = [];
    }
    if (changes.domicilio) {
      if (this.domicilio?.rows?.length >= 0) {
        this.rows = [...this.domicilio.rows];

        if (
          this.domicilio.selected != undefined &&
          this.domicilio.selected != null
        )
          this.selected = this.domicilio.selected;
      }
      if (
        this.domicilio?.showFormCrearDomicilio ||
        this.domicilio?.showTableDomicilio
      ) {
        this.showForm = this.domicilio.showFormCrearDomicilio;
        this.showTable = this.domicilio.showTableDomicilio;
      }
      if (this.domicilio) {
        if (
          this.domicilio.selected === undefined ||
          this.domicilio.selected === null
        ) {
          this.formDomicilio.patchValue(this.domicilio);
          this.changueCodigo();
        }
      }
    }
    if (changes.asegurado && !changes.asegurado.firstChange) {
      this.crearDomicilio();
    }
    if (changes.typePerson && !changes.typePerson.firstChange) {
      this.crearDomicilio();
    }
  }
  get f() {
    return this.formDomicilio.controls;
  }
  changueCodigo() {
    let postalCode = this.f.cdpostal.value;

    if (postalCode && this.f.cdpostal.valid) {
      this.apiCabinaService.getLocalidad(postalCode).subscribe(
        (resp) => {
          this.localidad = resp;

          this.formDomicilio.patchValue({
            pais: this.localidad.dspais,
            ciudad: this.localidad.dsciudad,
            estado: this.localidad.dsprovin,
            municipio: this.localidad.dsmunici,
          });
          if (this.localidad.cdpais === null) {
            this.localidad = null;
            this.f.colonia.setValue(null);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.formDomicilio.patchValue({
        pais: null,
        ciudad: null,
        estado: null,
        municipio: null,
        colonia: null,
      });
    }

    if (postalCode)
      this.apiCabinaService.getColonias(postalCode).subscribe(
        (resp) => {
          this.arrayColonias = resp;
          if (resp.length <= 0) {
            this.f.cdpostal.setErrors({ incorrect: true });
          } else {
            let existColonia = this.arrayColonias.find(
              (x: any) => x.code === this.f.colonia.value
            );

            if (!existColonia) {
              this.f.colonia.setValue(null);
            }

            this.f.cdpostal.setErrors(null);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  showHideTable() {
    this.showForm = !this.showForm;
    this.showTable = !this.showTable;
    this.crearDomicilio();
  }

  manageAction(e: any) {
    if (e[0]) this.selected = e[0].indice;

    this.domicilio.selected = this.selected;
    this.domicilio = { ...this.domicilio, ...e[0] };

    this.domicilio.showFormCrearDomicilio = this.showForm;
    this.domicilio.showTableDomicilio = this.showTable;
  }
  crearDomicilio() {
    this.formDomicilio.reset();

    this.localidad = null;

    let temporalRows = this.domicilio?.rows;
    Object.keys(this.domicilio).forEach((key) => (this.domicilio[key] = null));
    this.domicilio.rows = temporalRows;

    this.domicilio.showFormCrearDomicilio = this.showForm;
    this.domicilio.showTableDomicilio = this.showTable;
    this.domicilio.selected = null;
    this.selected = null;
    if (this.asegurado == false) {
      this.showForm = true;
      this.showTable = false;
      this.domicilio.showFormCrearDomicilio = this.showForm;
      this.domicilio.showTableDomicilio = this.showTable;
    }

    if (this.localidad?.cdpais === null || this.localidad === null) {
      this.localidad = null;
      this.f.colonia.setValue(null);
      this.arrayColonias = [];
    }
  }

  getForm() {
    let localidad;
    if (
      this.domicilio.selected != undefined &&
      this.domicilio.selected != null
    ) {
      return this.domicilio;
    } else {
      if (this.localidad) {
        localidad = {
          pais: this.localidad.dspais,
          ciudad: this.localidad.dsciudad,
          estado: this.localidad.dsprovin,
          municipio: this.localidad.dsmunici,
          cdciudad: this.localidad.cdciudad,
          cdmunicipio: this.localidad.cdmunici,
          cdpais: this.localidad.cdpais,
          cdestado: this.localidad.cdprovin,
        };
      }
      this.domicilio = {
        ...this.domicilio,
        ...this.formDomicilio.getRawValue(),
        ...localidad,
      };

      if (this.formDomicilio.valid) {
        return this.domicilio;
      } else {
        return null;
      }
    }
  }
}
