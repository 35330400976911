import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiBienestarService } from 'projects/bienestar/src/app/service/api-bienestar.service';
import {
  PersonaFisica,
  PersonaMoral,
} from 'projects/monitor-bc/src/app/cabina/models/cabina.interfaces';

import { getFormattedDate } from 'src/functions/common-functions';
import {
  menorEdad,
  validateFisicaRFC,
  validateMoralRFC,
} from 'src/functions/custom-validators';

@Component({
  selector: 'formulario-agente',
  templateUrl: './formulario-agente.component.html',
  styleUrls: ['./formulario-agente.component.scss'],
})
export class FormularioAgenteComponent implements OnInit {
  breadcrumbs = [
    {
      label: 'Listado de Pólizas',
      link: '/dashboard-bienestar/agente/polizas',
    },
    { label: 'Detalle de Póliza' },
  ];
  stateBar: any = {};
  formFisica: FormGroup;
  formMoral: FormGroup;
  personaFisica: PersonaFisica;
  personaMoral: PersonaMoral;
  filterBy: string = 'fisica';
  rows: any = [];
  isSearching: any;
  setSelected: any;
  selected: any;
  modalStep = 1;
  showTable = false;
  toDay = new Date();
  dateString: string;
  dateString2: string;
  waitingForApi = false;
  noDataError = false;

  errorFechaNacimiento: string;

  optionalValues = true;

  listadoPoliza;
  polizaSeleccionada;
  arrayRamos = [];
  tableHeaders;
  tableHeadersMobile;

  @Input() formAgente;
  @Input() modulo;
  @Input() poliza;
  @Input() tipoTramite?;

  @Output() setStepAgente = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private apiBienestarService: ApiBienestarService
  ) {}

  ngOnInit(): void {
    this.setHeaders(this.modulo);
    this.loadFormFisica();
    this.loadRamos();

    if (this.formAgente) {
      this.formFisica.patchValue(this.formAgente);

      this.dateString = this.formAgente.fechaOcurrencia;
      this.dateString2 = this.formAgente.fechaNacimiento;
    }
    if (this.poliza) {
      this.listadoPoliza = this.poliza.listado_poliza;

      if (this.poliza.listado_poliza.length > 0) {
        this.fillTable(this.listadoPoliza, this.modulo);

        this.showTable = true;

        this.polizaSeleccionada = this.poliza.poliza_seleccionada;
        this.selected = this.poliza.poliza_seleccionada.indice;
      }
    }
  }

  changeSelect(e) {
    this.filterBy = e;

    this.selected = null;
    this.showTable = false;
    this.rows = [];

    if (this.filterBy == 'fisica') this.loadFormFisica();
    if (this.filterBy == 'moral') this.loadFormMoral();
  }

  get f() {
    return this.formFisica.controls;
  }
  get m() {
    return this.formMoral.controls;
  }

  loadFormFisica() {
    this.formFisica = this.fb.group({
      select: ['fisica'],
      rfc: [
        this.personaFisica?.rfc ? this.personaFisica?.rfc : '',
        {
          validators: [validateFisicaRFC()],
          updateOn: 'blur',
        },
      ],
      curp: [this.personaFisica?.curp ? this.personaFisica?.curp : ''],
      oficina: [this.personaFisica?.oficina ? this.personaFisica?.oficina : ''],
      ramo: [this.personaFisica?.ramo ? this.personaFisica?.ramo : ''],
      poliza: [this.personaFisica?.poliza ? this.personaFisica?.poliza : ''],
      fechaOcurrencia: [
        this.personaFisica?.fechaOcurrencia,
        Validators.required,
      ],
      primerNombre: [this.personaFisica?.primerNombre, Validators.required],
      segundoNombre: [
        this.personaFisica?.segundoNombre
          ? this.personaFisica?.segundoNombre
          : '',
      ],
      primerApellido: [this.personaFisica?.primerApellido, Validators.required],
      segundoApellido: [
        this.personaFisica?.segundoApellido
          ? this.personaFisica?.segundoApellido
          : '',
      ],
      fechaNacimiento: [
        this.personaFisica?.fechaNacimiento
          ? this.personaFisica?.fechaNacimiento
          : '',
        {
          validators: [menorEdad()],
          updateOn: 'blur',
        },
      ],
      certificado: [
        this.personaFisica?.certificado ? this.personaFisica?.certificado : '',
      ],
    });

    // this.f.select.setValue('fisica');
    console.log(this.f);
  }

  loadFormMoral() {
    this.formMoral = this.fb.group({
      select: ['moral'],
      nombreEmpresa: [
        this.personaMoral?.nombreEmpresa ? this.personaMoral.nombreEmpresa : '',
      ],
      fechaNacimiento: [
        this.personaMoral?.fechaNacimiento
          ? this.personaMoral.fechaNacimiento
          : '',
        Validators.required,
      ],
      rfc: [
        this.personaMoral?.rfc ? this.personaMoral?.rfc : '',
        {
          validators: [validateMoralRFC()],
          updateOn: 'blur',
        },
      ],
      oficina: [this.personaMoral?.oficina ? this.personaMoral.oficina : ''],
      ramo: [this.personaMoral?.ramo ? this.personaMoral.ramo : ''],
      poliza: [this.personaMoral?.poliza ? this.personaMoral.poliza : ''],
      fechaOcurrencia: [
        this.personaMoral?.fechaOcurrencia
          ? this.personaMoral.fechaOcurrencia
          : '',
        Validators.required,
      ],
      certificado: [
        this.personaMoral?.certificado ? this.personaMoral?.certificado : '',
      ],
    });
  }

  manageAction(e: any) {
    if (e[0]) this.selected = e[0].indice;

    // this.cabinaService.aseguradoCabina = e[0];

    this.polizaSeleccionada = e[0];
  }

  continuar() {
    let fecha_busqueda;

    if (this.filterBy == 'fisica')
      fecha_busqueda = this.formFisica.value.fechaOcurrencia;
    else fecha_busqueda = this.formMoral.value.fechaOcurrencia;

    const poliza = {
      fecha_busqueda,
      listado_poliza: this.listadoPoliza,
      poliza_seleccionada: this.polizaSeleccionada,
      error: false,
      buscandoPoliza: false,
      msgError: '',
    };

    let obj;
    if (this.filterBy == 'fisica') {
      obj = { ...this.formFisica.value };
      obj.fechaOcurrencia = getFormattedDate(obj.fechaOcurrencia, 'yyyy-mm-dd');
      this.setStepAgente.emit({ formAgente: obj, poliza });
    } else {
      obj = { ...this.formMoral.value };
      obj.fechaOcurrencia = getFormattedDate(obj.fechaOcurrencia, 'yyyy-mm-dd');
      this.setStepAgente.emit({ formAgente: obj, poliza });
    }
  }

  regresar() {}

  backStepModal() {
    this.modalStep = 1;
  }

  finish() {}

  search() {
    this.optionalValues = true;
    this.rows = [];
    this.showTable = false;
    this.selected = null;

    let count;
    if (this.filterBy == 'fisica') {
      console.log(this.formFisica);

      let data = this.formFisica.getRawValue();

      count = 0;
      for (const property in this.formFisica.getRawValue()) {
        if (data[property] != '' && data[property] != null) {
          count++;
        }
      }
      if (count < 7) {
        this.optionalValues = false;
        console.log(
          'Debe completar al menos tres de los campos opcionales indicados'
        );
      }
    } else {
      let data = this.formMoral.getRawValue();

      count = 0;
      for (const property in this.formMoral.getRawValue()) {
        if (data[property] != '' && data[property] != null) {
          count++;
        }
      }
      if (count < 6) {
        this.optionalValues = false;
        console.log(
          'Debe completar al menos tres de los campos opcionales indicados'
        );
      }
    }

    this.waitingForApi = true;

    console.log(this.formFisica.value);

    if (this.optionalValues) {
      if (this.filterBy == 'fisica') {
        this.setUppercase(this.formFisica.value);
        this.apiBienestarService
          .getPolizasAgenteMonitor(this.formFisica.value, 'fisica', this.modulo, this.tipoTramite)
          .subscribe(
            (response) => {
              this.fillTable(response, this.modulo);

              this.listadoPoliza = response;
              this.waitingForApi = false;
              if (this.rows.length < 1) this.noDataError = true;
              else this.noDataError = false;

              this.showTable = true;
            },
            (error) => {
              this.waitingForApi = false;
              this.noDataError = true;
              this.showTable = true;
            }
          );
      }
      if (this.filterBy == 'moral') {
        this.setUppercase(this.formMoral.value);
        let obj = { ...this.formMoral.value };
        obj.fechaConstitucion = getFormattedDate(
          obj.fechaNacimiento,
          'yyyy-mm-dd'
        );
        delete obj['fechaNacimiento'];
        this.apiBienestarService
          .getPolizasAgenteMonitor(obj, 'moral', this.modulo)
          .subscribe(
            (response) => {
              this.fillTable(response, this.modulo);

              this.listadoPoliza = response;
              this.waitingForApi = false;
              if (this.rows.length < 1) this.noDataError = true;
              else this.noDataError = false;
              this.showTable = true;
            },
            (error) => {
              this.waitingForApi = false;
              this.noDataError = true;
              this.showTable = true;
            }
          );
      }
    } else this.waitingForApi = false;
  }

  getFechaOcurrencia(e) {
    if (this.filterBy == 'fisica') {
      this.f.fechaOcurrencia.markAsTouched();
    }
    if (this.filterBy == 'moral') {
      this.m.fechaOcurrencia.markAsTouched();
    }

    if (e.isValid) {
      if (this.filterBy == 'fisica') {
        this.f.fechaOcurrencia.setValue(e.date);
        this.f.fechaOcurrencia.setErrors(null);
        console.log(this.formFisica);
      }
      if (this.filterBy == 'moral') {
        this.m.fechaOcurrencia.setValue(e.date);
        this.m.fechaOcurrencia.setErrors(null);
      }
    } else {
      if (this.filterBy == 'fisica') {
        this.f.fechaOcurrencia.setErrors({ labelInvalid: true });
      }
      if (this.filterBy == 'moral') {
        console.log('sfsf');
        this.m.fechaOcurrencia.setErrors({ labelInvalid: true });
      }
    }
  }

  getFechaConstitucion(e) {
    this.f.fechaNacimiento.markAsTouched();
    this.m.fechaNacimiento.markAsTouched();
    if (e.date == '' && this.filterBy == 'fisica') {
      e.isValid = true;
      this.f.fechaNacimiento.setValue('');
    }

    console.log(e);

    if (e.isValid) {
      this.f.fechaNacimiento.setErrors(null);
      this.m.fechaNacimiento.setErrors(null);
      if (this.filterBy == 'fisica') {
        this.f.fechaNacimiento.setValue(e.date);
      }
      if (this.filterBy == 'moral') {
        this.m.fechaNacimiento.setValue(e.date);
      }
    } else {
      this.f.fechaNacimiento.setErrors({ labelInvalid: true });
      this.m.fechaNacimiento.setErrors({ labelInvalid: true });
    }
  }

  getFechaNacimiento(e) {
    this.f.fechaNacimiento.markAsTouched();

    if (e.date == '') {
      e.isValid = true;
      this.f.fechaNacimiento.setValue('');
    } else {
      this.f.fechaNacimiento.setValue(e.date);
    }
  }

  addIndex(rows) {
    rows.forEach(function (row, index) {
      row.indice = index;
    });
  }

  addND(rows) {
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index];

      for (let key in element) {
        if (element[key] == null || element[key] == undefined)
          element[key] = 'N/D';
      }
    }
  }

  test() {
    console.log(this.f);
  }

  setUppercase(obj) {
    for (let key in obj) {
      if (obj[key] && key != 'select') {
        obj[key] = obj[key].toString().toUpperCase();
      }
    }
  }

  fillTable(data, modulo) {
    this.noDataError = false;
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      let keys = Object.keys(element);
      for (let index = 0; index < keys.length; index++) {
        if (element[keys[index]] == null) {
          element[keys[index]] = '-';
        }
      }

      switch (modulo) {
        case 'vida':
          this.rows.push({
            titular: element.titular,
            numero: element.poliza,
            nmpoliza: element.nmpoliza,
            fechaUltimo: element.ultimoPago,
            vigencia:
              getFormattedDate(element.vigenciaInicio, 'dd/mm/yyyy') +
              ' - ' +
              getFormattedDate(element.vigenciaFin, 'dd/mm/yyyy'),
            fechaInicio: element.vigenciaInicio,
            fechaFin: element.vigenciaFin,
            asegurados: element.asegurados,
            coberturas: element.coberturas,
            ramo: element.ramo,
            oficina: element.oficina,
            nmsuplemen: element.nmsuplemen,
            ramoDescripcion: element.ramoDescripcion,
            indice: index,
          });

          break;

        case 'gmm':
          this.rows.push({
            asegurado: element.asegurado,
            contratante: element.contratante,
            nmpoliza: element.nmpoliza,
            estado: element.vigenciaEstado,
            fechaUltimoPago: element.ultimoPago,
            fechaInicioVigencia: element.vigenciaInicio,
            fechaFinVigencia: element.vigenciaFin,
            oficina: element.oficina,
            ramo: element.ramo,
            poliza: element.poliza,
            polizaConcatenada:
              element.nmpoliza + '-' + element.asegurados[0].nmsituac,
            nmsuplemen: element.nmsuplemen,
            nmsituac: element.asegurados[0].nmsituac,
            titular: element.titular,
            cdperson: element.aseguradoCdperson,
            asegurados: element.asegurados,
            indice: index,
          });

          break;

        default:
          break;
      }
    }
  }

  loadRamos() {
    this.apiBienestarService.getRamos(this.modulo, this.tipoTramite).subscribe((res) => {
      this.arrayRamos = res.ramos;
    });
  }

  precargarForm() {
    // this.formAgente = {
    //   rfc: 'SOOJ890923SV5',
    //   oficina: 1,
    //   ramo: 421,
    //   poliza: 481,
    //   fechaOcurrencia: '2021-03-23',
    //   primerNombre: 'JESUS',
    //   segundoNombre: 'ROBERTO',
    //   primerApellido: 'SOLORZANO',
    //   segundoApellido: 'OLVERA',
    //   fechaNacimiento: '1989-09-23',
    //   cdAgente: '000001',
    //   modulo: 'vida',
    // };

    // this.formAgente = {
    //   rfc: 'XAOP810101000',
    //   curp: 'XAOP810101000HBDY3',
    //   oficina: 1,
    //   ramo: 606,
    //   poliza: 18273,
    //   fechaOcurrencia: '2021-08-24',
    //   primerNombre: 'PATRICIA',
    //   primerApellido: 'XOXA',
    //   fechaNacimiento: '1981-01-01',
    //   cdAgente: '004368',
    //   modulo: 'gmm',
    // };

    // this.formAgente = {
    //   nombreEmpresa: 'TEST',
    //   fechaNacimiento: '1967-06-16',
    //   rfc: 'FER670616',
    //   oficina: 1,
    //   ramo: 606,
    //   poliza: 15757,
    //   fechaOcurrencia: '2008-10-01',
    // };
    // if (this.formAgente) this.formMoral.patchValue(this.formAgente);

    this.formAgente = {
      rfc: 'SOOJ890923SV5',
      oficina: 1,
      ramo: 421,
      poliza: 478,
      fechaOcurrencia: '2021-12-01',
      primerNombre: 'JESUS',
      segundoNombre: 'ROBERTO',
      primerApellido: 'SOLORZANO',
      segundoApellido: 'OLVERA',
      fechaNacimiento: '1989-09-23',
      cdAgente: '000001',
      modulo: 'vida',
    };

    if (this.formAgente) this.formFisica.patchValue(this.formAgente);
  }

  setHeaders(modulo) {
    switch (modulo) {
      case 'vida':
        this.tableHeaders = [
          {
            name: 'titular',
            label: 'Titular',
          },
          {
            name: 'nmpoliza',
            label: 'Número de Póliza',
          },
          {
            name: 'fechaUltimo',
            label: 'Fecha último pago',
          },
          {
            name: 'vigencia',
            label: 'Vigencia',
          },
          {
            name: 'ramoDescripcion',
            label: 'Tipo de Póliza',
          },
        ];

        this.tableHeadersMobile = [
          {
            name: 'titular',
            label: 'Titular',
          },
          {
            name: 'nmpoliza',
            label: 'Número de Póliza',
          },
        ];

        break;

      case 'gmm':
        this.tableHeaders = [
          {
            name: 'asegurado',
            label: 'Asegurado',
          },
          {
            name: 'contratante',
            label: 'Contratante',
          },
          {
            name: 'polizaConcatenada',
            label: 'Póliza',
          },
          {
            name: 'estado',
            label: 'Estado',
          },
          {
            name: 'fechaUltimoPago',
            label: 'F. Último Pago',
          },
          {
            name: 'fechaInicioVigencia',
            label: 'F. Inicio Vigencia',
          },
          {
            name: 'fechaFinVigencia',
            label: 'F. Fin Vigencia',
          },
        ];

        this.tableHeadersMobile = [
          {
            name: 'asegurado',
            label: 'Asegurado',
          },
          {
            name: 'contratante',
            label: 'Contratante',
          },
          {
            name: 'poliza',
            label: 'Póliza',
          },
          {
            name: 'estado',
            label: 'Estado',
          },
        ];

        break;

      default:
        break;
    }
  }
}
